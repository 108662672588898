export const aboutData = {
  upperTitle: 'Childhood Cancer Data Initiative Hub',
  upperText: '<p>The Childhood Cancer Data Initiative (CCDI) Hub is an entry point for researchers, data scientists, and citizen scientists looking to use and connect with CCDI-supported data, tools, and applications.  The CCDI Hub’s mission is to support innovative research through increased accessibility of pediatric cancer research datasets and  resources.</p>'
  +'<p>It provides information about available tools and applications that support the CCDI vision, along with descriptions of resources, each of which targets specific aspects of childhood cancer research.  The Explore Dashboard, an integrated tool of the CCDI Hub, provides participant-centric search functionality by connecting participants with files and samples. The Explore Dashboard enables researchers to find data within a single study or across multiple studies and create synthetic cohorts based on filtered metrics (I.e., demographics, diagnosis, samples, etc.) of interest.</p>'
  +'<p>The Hub also provides direct links to resources and additional technical information. Users are invited to explore the Hub or select a resource’s link to learn more.</p>', 
  lowerTitle: 'Childhood Cancer Data Initiative',
  lowerText: "The <a className='aboutLink' href='https://www.cancer.gov/research/areas/childhood/childhood-cancer-data-initiative' target='_blank' rel='noopener noreferrer'>NCI's Childhood Cancer Data Initiative (CCDI)</a> is building a community centered around childhood cancer care and research data. By connecting, analyzing, and easily sharing data among researchers, we can increase our understanding of childhood cancers and improve treatment, quality of life, and survivorship for all children, adolescents, and young adults with cancer.",
  aboutText: '<p>The NCI is committed to supporting the pediatric cancer research community. We value your input and are here to help you with any questions or feedback regarding the CCDI Hub.</p>'
  +"<p>For all inquiries, including questions about the CCDI Hub, its resources, or how to get involved please send an <a className='aboutContactLink' href='mailto:ncichildhoodcancerdatainitiative@mail.nih.gov' target='_blank' rel='noopener noreferrer'>email to Childhood Cancer Data Initiative</a>.</p>"
  +'<p>Our team is dedicated to:</p>'
  +'<ul><li>Answering your questions about the CCDI Hub and its features</li>'
  +'<li>Providing support for using the CCDI Hub Explore Dashboard.</li>'
  +'<li>Receiving feedback on how we can improve the CCDI Hub Explore Dashboard:  recommendations for additional filtering or search functionalities, suggestions for enhancing the user interface or navigation, feedback on performance or responsiveness of the dashboard, etc.</li></ul>'
  +'<p>We look forward to hearing from you and enhancing the CCDI Hub to better serve the pediatric cancer research community.</p>',
};
import { sortType, InputTypes } from '@bento-core/facet-filter';
import clearButton from '../assets/icons/Clear_Icon.svg';
import clearButtonActive from '../assets/icons/Clear_Icon_Active.svg';
import clearButtonActiveHover from '../assets/icons/Clear_Icon_White.svg';

const DEMOGRAPHICS = 'Demographics';
const SURVIVAL = 'Survival';
const TREATMENT = 'Treatment';
const TREATMENT_RESPONSE = 'Treatmentresponse';
const DIAGNOSIS = 'Diagnosis';
// const CASES = 'Cases';
const SAMPLES = 'Samples';
const DATACATEGORY = 'Datacategory';
const SEQUENCINGLIBRARY = 'Sequencinglibrary';
// const FILES = 'Files';
const STUDY = 'Study';
const GROUP = 'group';

// --------------- Facet resetIcon link configuration --------------
// Ideal size for resetIcon is 16x16 px
export const resetIcon = {
  src: clearButton,
  srcActive: clearButtonActive,
  srcActiveHover: clearButtonActiveHover,
  alt: 'Reset icon',
  size: '12px',
};

export const sectionLabel = {
  Datacategory: "Data Category",
  Sequencinglibrary: 'Sequencing Library',
  Treatmentresponse: 'Treatment Response'
};

// --------------- Dashboard Sidebar Sections styling --------------
export const facetSectionVariables = {
  Diagnosis: {
    isExpanded: true,
  },
  Demographics: {
    isExpanded: false,
    hasSearch: true,
  },
  Treatment: {
    isExpanded: false,
  },
  Treatmentresponse: {
    isExpanded: false,
  },
  // Cases: {
  //   isExpanded: true,
  //   hasSearch: true,
  // },
  Survival: {
    isExpanded: false,
  },
  Samples: {
    isExpanded: false,
  },
  Datacategory: {
    isExpanded: false,
  },
  Study: {
    isExpanded: false,
  },
  Sequencinglibrary: {
    isExpanded: false,
  },
  // Files: {
  //   isExpanded: true,
  // },
};

export const facetsConfig = [
  {
    section: DIAGNOSIS,
    label: 'Diagnosis',
    apiPath: 'participantCountByDiagnosis',
    apiForFiltering: 'filterParticipantCountByDiagnosis',
    datafield: 'diagnosis',
    field: GROUP,
    type: InputTypes.CHECKBOX,
    sort_type: sortType.ALPHABET,
    show: true,
    search: true,
    expanded: true,
  },
  {
    section: DIAGNOSIS,
    label: 'Diagnosis Anatomic site',
    apiPath: 'participantCountByAnatomicSite',
    apiForFiltering: 'filterParticipantCountByDiagnosisAnatomicSite',
    datafield: 'diagnosis_anatomic_site',
    field: GROUP,
    type: InputTypes.CHECKBOX,
    sort_type: sortType.ALPHABET,
    show: true,
    search: true,
  },
  {
    section: DIAGNOSIS,
    label: 'Age at diagnosis (days)',
    apiPath: 'filterParticipantCountByDiagnosisAge',
    apiForFiltering: 'filterParticipantCountByDiagnosisAge',
    datafield: 'age_at_diagnosis',
    ApiLowerBoundName: 'lowerBound',
    ApiUpperBoundName: 'upperBound',
    show: true,
    slider: true,
    type: InputTypes.SLIDER,
    sort_type: 'none',
    minLowerBound: 0,
    maxUpperBound: 100,
    quantifier: 'Days',
    style: {
      colorPrimary: {
        color: '#4D889E',
      },
      sliderRoot: {
        marginTop: '1px',
        marginLeft: '20px',
        marginRight: 'Auto',
        paddingRight: '20px',
      },
      minValue: {
        fontFamily: 'Nunito',
        fontSize: '15px',
        color: '#000000',
        marginBottom: '0px',
        float: 'left',
        width: '50%',
        display: 'flex',
      },
      maxValue: {
        fontFamily: 'Nunito',
        fontSize: '15px',
        color: '#000000',
        float: 'right',
        marginBottom: '0px',
        width: '50%',
        display: 'flex',
      },
      rail: {
        borderRadius: 4,
        height: 6,
        background: '#142D64',
      },
      minInputLabel: {
        float: 'left',
        lineHeight: '34px',
        marginRight: '5px',
        fontFamily: 'Montserrat',
        fontSize: '11px',
        fontWeight: 500,
      },
      maxInputLabel: {
        float: 'left',
        lineHeight: '34px',
        marginRight: '5px',
        fontFamily: 'Montserrat',
        fontSize: '11px',
        fontWeight: 500,
      },
      thumb: {
        height: 16,
        width: 16,
        background: '#4D889E',
      },
      invalidThumb: {
        height: 16,
        width: 16,
        background: '#F44336',
      },
      track: {
        borderRadius: 4,
        height: 6,
        background: '#4D889E',
        '&~&': {
          background: '#142D64',
        },
      },
      invalidTrack: {
        borderRadius: 4,
        height: 6,
        background: '#F44336',
        '&~&': {
          background: '#142D64',
        },
      },
      upperBound: {
        fontFamily: 'Nunito',
        fontSize: '10px',
        color: '#000000',
        float: 'right',
        marginLeft: 'Auto',
        marginRight: 'Auto',
        marginBottom: '15px',
      },
      lowerBound: {
        fontFamily: 'Nunito',
        fontSize: '10px',
        color: '#000000',
        float: 'left',
        marginLeft: 'Auto',
        marginRight: 'Auto',
        marginBottom: '15px',
      },
      sliderText: {
        color: '#7D267E',
        lineHeight: '120%',
        fontFamily: 'Nunito',
        fontSize: '14px',
        padding: '5px 15px 5px 0px',
        width: '100%',
        textAlign: 'right',
        background: '#C8A4C840',
        marginTop: '10px',
      },
      invalidSliderText: {
        color: '#D32F2F',
        lineHeight: '120%',
        fontFamily: 'Nunito',
        fontSize: '14px',
        padding: '5px 15px 5px 0px',
        width: '100%',
        textAlign: 'right',
        background: '#E57373',
        marginTop: '10px',
      },
      sliderListItem: {
        height: '15px',
      },
      listItemGutters: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '2px 5px 2px 8px',
      },
      lowerUpperBound: {
        height: '15px',
      },
    },
  },
  {
    section: DIAGNOSIS,
    label: 'Diagnosis Classification System',
    apiPath: 'participantCountByDiagnosisClassificationSystem',
    apiForFiltering: 'filterParticipantCountByDiagnosisClassificationSystem',
    datafield: 'diagnosis_classification_system',
    field: GROUP,
    type: InputTypes.CHECKBOX,
    sort_type: sortType.ALPHABET,
    show: true,
  },
  {
    section: DIAGNOSIS,
    label: 'Diagnosis Basis',
    apiPath: 'participantCountByDiagnosisBasis',
    apiForFiltering: 'filterParticipantCountByDiagnosisBasis',
    datafield: 'diagnosis_basis',
    field: GROUP,
    type: InputTypes.CHECKBOX,
    sort_type: sortType.ALPHABET,
    show: true,
  },
  /*{
    section: DIAGNOSIS,
    label: 'Tumor Grade Source',
    apiPath: 'participantCountByTumorGradeSource',
    apiForFiltering: 'filterParticipantCountByTumorGradeSource',
    datafield: 'tumor_grade_source',
    field: GROUP,
    type: InputTypes.CHECKBOX,
    sort_type: sortType.ALPHABET,
    show: true,
  },
  {
    section: DIAGNOSIS,
    label: 'Tumor Stage Source',
    apiPath: 'participantCountByTumorStageSource',
    apiForFiltering: 'filterParticipantCountByTumorStageSource',
    datafield: 'tumor_stage_source',
    field: GROUP,
    type: InputTypes.CHECKBOX,
    sort_type: sortType.ALPHABET,
    show: true,
  },*/
  {
    section: DIAGNOSIS,
    label: 'Disease Phase',
    apiPath: 'participantCountByDiseasePhase',
    apiForFiltering: 'filterParticipantCountByDiseasePhase',
    datafield: 'disease_phase',
    field: GROUP,
    type: InputTypes.CHECKBOX,
    sort_type: sortType.ALPHABET,
    show: true,
  },
  {
    section: DEMOGRAPHICS,
    label: 'Sex',
    apiPath: 'participantCountBySexAtBirth',
    apiForFiltering: 'filterParticipantCountBySexAtBirth',
    datafield: 'sex_at_birth',
    field: GROUP,
    type: InputTypes.CHECKBOX,
    sort_type: sortType.ALPHABET,
    show: true,
  },
  {
    section: DEMOGRAPHICS,
    label: 'Race',
    apiPath: 'participantCountByRace',
    apiForFiltering: 'filterParticipantCountByRace',
    datafield: 'race',
    field: GROUP,
    type: InputTypes.CHECKBOX,
    sort_type: sortType.ALPHABET,
    show: true,
  },
  {
    section: TREATMENT,
    label: 'Age at Treatment Start',
    apiPath: 'participantCountByAgeAtTreatmentStart',
    apiForFiltering: 'filterParticipantCountByAgeAtTreatmentStart',
    datafield: 'age_at_treatment_start',
    ApiLowerBoundName: 'lowerBound',
    ApiUpperBoundName: 'upperBound',
    show: true,
    slider: true,
    type: InputTypes.SLIDER,
    sort_type: 'none',
    minLowerBound: 0,
    maxUpperBound: 100,
    quantifier: 'Days',
    style: {
      colorPrimary: {
        color: '#4150A4',
      },
      sliderRoot: {
        marginTop: '1px',
        marginLeft: '20px',
        marginRight: 'Auto',
        paddingRight: '20px',
      },
      minValue: {
        fontFamily: 'Nunito',
        fontSize: '15px',
        color: '#000000',
        marginBottom: '0px',
        float: 'left',
        width: '75px',
        display: 'flex',
      },
      maxValue: {
        fontFamily: 'Nunito',
        fontSize: '15px',
        color: '#000000',
        float: 'right',
        marginBottom: '0px',
        display: 'flex',
      },
      rail: {
        borderRadius: 4,
        height: 6,
        background: '#142D64',
      },
      minInputLabel: {
        float: 'left',
        lineHeight: '34px',
        marginRight: '5px',
        fontFamily: 'Montserrat',
        fontSize: '11px',
        fontWeight: 500,
      },
      maxInputLabel: {
        float: 'left',
        lineHeight: '34px',
        marginRight: '5px',
        fontFamily: 'Montserrat',
        fontSize: '11px',
        fontWeight: 500,
      },
      thumb: {
        height: 16,
        width: 16,
        background: '#4150A4',
      },
      invalidThumb: {
        height: 16,
        width: 16,
        background: '#F44336',
      },
      track: {
        borderRadius: 4,
        height: 6,
        background: '#4150A4',
        '&~&': {
          background: '#142D64',
        },
      },
      invalidTrack: {
        borderRadius: 4,
        height: 6,
        background: '#F44336',
        '&~&': {
          background: '#142D64',
        },
      },
      upperBound: {
        fontFamily: 'Nunito',
        fontSize: '10px',
        color: '#000000',
        float: 'right',
        marginLeft: 'Auto',
        marginRight: 'Auto',
        marginBottom: '15px',
      },
      lowerBound: {
        fontFamily: 'Nunito',
        fontSize: '10px',
        color: '#000000',
        float: 'left',
        marginLeft: 'Auto',
        marginRight: 'Auto',
        marginBottom: '15px',
      },
      sliderText: {
        color: '#897E67',
        lineHeight: '120%',
        fontFamily: 'Nunito',
        fontSize: '14px',
        padding: '5px 15px 5px 0px',
        width: '100%',
        textAlign: 'right',
        background: '#D9C5A040',
        marginTop: '10px',
      },
      invalidSliderText: {
        color: '#D32F2F',
        lineHeight: '120%',
        fontFamily: 'Nunito',
        fontSize: '14px',
        padding: '5px 15px 5px 0px',
        width: '100%',
        textAlign: 'right',
        background: '#E57373',
        marginTop: '10px',
      },
      sliderListItem: {
        height: '15px',
      },
      listItemGutters: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '2px 5px 2px 8px',
      },
      lowerUpperBound: {
        height: '15px',
      },
    }
  },
  {
    section: TREATMENT,
    label: 'Treatment Agent',
    apiPath: 'participantCountByTreatmentAgent',
    apiForFiltering: 'filterParticipantCountByTreatmentAgent',
    datafield: 'treatment_agent',
    field: GROUP,
    type: InputTypes.CHECKBOX,
    sort_type: sortType.ALPHABET,
    show: true,
  },
  {
    section: TREATMENT,
    label: 'Treatment Type',
    apiPath: 'participantCountByTreatmentType',
    apiForFiltering: 'filterParticipantCountByTreatmentType',
    datafield: 'treatment_type',
    field: GROUP,
    type: InputTypes.CHECKBOX,
    sort_type: sortType.ALPHABET,
    show: true,
  },
  {
    section: TREATMENT_RESPONSE,
    label: 'Age at Response',
    apiPath: 'participantCountByAgeAtResponse',
    apiForFiltering: 'filterParticipantCountByAgeAtResponse',
    datafield: 'age_at_response',
    ApiLowerBoundName: 'lowerBound',
    ApiUpperBoundName: 'upperBound',
    show: true,
    slider: true,
    type: InputTypes.SLIDER,
    sort_type: 'none',
    minLowerBound: 0,
    maxUpperBound: 100,
    quantifier: 'Days',
    style: {
      colorPrimary: {
        color: '#E9B34A',
      },
      sliderRoot: {
        marginTop: '1px',
        marginLeft: '20px',
        marginRight: 'Auto',
        paddingRight: '20px',
      },
      minValue: {
        fontFamily: 'Nunito',
        fontSize: '15px',
        color: '#000000',
        marginBottom: '0px',
        float: 'left',
        width: '75px',
        display: 'flex',
      },
      maxValue: {
        fontFamily: 'Nunito',
        fontSize: '15px',
        color: '#000000',
        float: 'right',
        marginBottom: '0px',
        display: 'flex',
      },
      rail: {
        borderRadius: 4,
        height: 6,
        background: '#142D64',
      },
      minInputLabel: {
        float: 'left',
        lineHeight: '34px',
        marginRight: '5px',
        fontFamily: 'Montserrat',
        fontSize: '11px',
        fontWeight: 500,
      },
      maxInputLabel: {
        float: 'left',
        lineHeight: '34px',
        marginRight: '5px',
        fontFamily: 'Montserrat',
        fontSize: '11px',
        fontWeight: 500,
      },
      thumb: {
        height: 16,
        width: 16,
        background: '#E9B34A',
      },
      invalidThumb: {
        height: 16,
        width: 16,
        background: '#F44336',
      },
      track: {
        borderRadius: 4,
        height: 6,
        background: '#E9B34A',
        '&~&': {
          background: '#142D64',
        },
      },
      invalidTrack: {
        borderRadius: 4,
        height: 6,
        background: '#F44336',
        '&~&': {
          background: '#142D64',
        },
      },
      upperBound: {
        fontFamily: 'Nunito',
        fontSize: '10px',
        color: '#000000',
        float: 'right',
        marginLeft: 'Auto',
        marginRight: 'Auto',
        marginBottom: '15px',
      },
      lowerBound: {
        fontFamily: 'Nunito',
        fontSize: '10px',
        color: '#000000',
        float: 'left',
        marginLeft: 'Auto',
        marginRight: 'Auto',
        marginBottom: '15px',
      },
      sliderText: {
        color: '#897E67',
        lineHeight: '120%',
        fontFamily: 'Nunito',
        fontSize: '14px',
        padding: '5px 15px 5px 0px',
        width: '100%',
        textAlign: 'right',
        background: '#D9C5A040',
        marginTop: '10px',
      },
      invalidSliderText: {
        color: '#D32F2F',
        lineHeight: '120%',
        fontFamily: 'Nunito',
        fontSize: '14px',
        padding: '5px 15px 5px 0px',
        width: '100%',
        textAlign: 'right',
        background: '#E57373',
        marginTop: '10px',
      },
      sliderListItem: {
        height: '15px',
      },
      listItemGutters: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '2px 5px 2px 8px',
      },
      lowerUpperBound: {
        height: '15px',
      },
    }
  },
  {
    section: TREATMENT_RESPONSE,
    label: 'Response Category',
    apiPath: 'participantCountByResponseCategory',
    apiForFiltering: 'filterParticipantCountByResponseCategory',
    datafield: 'response_category',
    field: GROUP,
    type: InputTypes.CHECKBOX,
    sort_type: sortType.ALPHABET,
    show: true,
  },
  {
    section: SURVIVAL,
    label: 'Age at Last Known Survival Status',
    apiPath: 'participantCountByAgeAtLastKnownSurvivalStatus',
    apiForFiltering: 'filterParticipantCountByAgeAtLastKnownSurvivalStatus',
    datafield: 'age_at_last_known_survival_status',
    ApiLowerBoundName: 'lowerBound',
    ApiUpperBoundName: 'upperBound',
    show: true,
    slider: true,
    type: InputTypes.SLIDER,
    sort_type: 'none',
    minLowerBound: 0,
    maxUpperBound: 100,
    quantifier: 'Days',
    style: {
      colorPrimary: {
        color: '#CD5C4E',
      },
      sliderRoot: {
        marginTop: '1px',
        marginLeft: '20px',
        marginRight: 'Auto',
        paddingRight: '20px',
      },
      minValue: {
        fontFamily: 'Nunito',
        fontSize: '15px',
        color: '#000000',
        marginBottom: '0px',
        float: 'left',
        width: '75px',
        display: 'flex',
      },
      maxValue: {
        fontFamily: 'Nunito',
        fontSize: '15px',
        color: '#000000',
        float: 'right',
        marginBottom: '0px',
        display: 'flex',
      },
      rail: {
        borderRadius: 4,
        height: 6,
        background: '#142D64',
      },
      minInputLabel: {
        float: 'left',
        lineHeight: '34px',
        marginRight: '5px',
        fontFamily: 'Montserrat',
        fontSize: '11px',
        fontWeight: 500,
      },
      maxInputLabel: {
        float: 'left',
        lineHeight: '34px',
        marginRight: '5px',
        fontFamily: 'Montserrat',
        fontSize: '11px',
        fontWeight: 500,
      },
      thumb: {
        height: 16,
        width: 16,
        background: '#CD5C4E',
      },
      invalidThumb: {
        height: 16,
        width: 16,
        background: '#F44336',
      },
      track: {
        borderRadius: 4,
        height: 6,
        background: '#CD5C4E',
        '&~&': {
          background: '#142D64',
        },
      },
      invalidTrack: {
        borderRadius: 4,
        height: 6,
        background: '#F44336',
        '&~&': {
          background: '#142D64',
        },
      },
      upperBound: {
        fontFamily: 'Nunito',
        fontSize: '10px',
        color: '#000000',
        float: 'right',
        marginLeft: 'Auto',
        marginRight: 'Auto',
        marginBottom: '15px',
      },
      lowerBound: {
        fontFamily: 'Nunito',
        fontSize: '10px',
        color: '#000000',
        float: 'left',
        marginLeft: 'Auto',
        marginRight: 'Auto',
        marginBottom: '15px',
      },
      sliderText: {
        color: '#897E67',
        lineHeight: '120%',
        fontFamily: 'Nunito',
        fontSize: '14px',
        padding: '5px 15px 5px 0px',
        width: '100%',
        textAlign: 'right',
        background: '#D9C5A040',
        marginTop: '10px',
      },
      invalidSliderText: {
        color: '#D32F2F',
        lineHeight: '120%',
        fontFamily: 'Nunito',
        fontSize: '14px',
        padding: '5px 15px 5px 0px',
        width: '100%',
        textAlign: 'right',
        background: '#E57373',
        marginTop: '10px',
      },
      sliderListItem: {
        height: '15px',
      },
      listItemGutters: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '2px 5px 2px 8px',
      },
      lowerUpperBound: {
        height: '15px',
      },
    }
  },
  {
    section: SURVIVAL,
    label: 'First Event',
    apiPath: 'participantFirstEvent',
    apiForFiltering: 'filterParticipantCountByFirstEvent',
    datafield: 'first_event',
    field: GROUP,
    type: InputTypes.CHECKBOX,
    sort_type: sortType.ALPHABET,
    show: true,
  },
  {
    section: SURVIVAL,
    label: 'Last Known Survival Status',
    apiPath: 'participantCountBySurvivalStatus',
    apiForFiltering: 'filterParticipantCountBySurvivalStatus',
    datafield: 'last_known_survival_status',
    field: GROUP,
    type: InputTypes.CHECKBOX,
    sort_type: sortType.ALPHABET,
    show: true,
  },
  {
    section: SAMPLES,
    label: 'Age at collection (days)',
    apiPath: 'filterParticipantCountBySampleAge',
    apiForFiltering: 'filterParticipantCountBySampleAge',
    datafield: 'participant_age_at_collection',
    ApiLowerBoundName: 'lowerBound',
    ApiUpperBoundName: 'upperBound',
    show: true,
    slider: true,
    type: InputTypes.SLIDER,
    sort_type: 'none',
    minLowerBound: 0,
    maxUpperBound: 100,
    quantifier: 'Days',
    style: {
      colorPrimary: {
        color: '#1F6BBF',
      },
      sliderRoot: {
        marginTop: '1px',
        marginLeft: '20px',
        marginRight: 'Auto',
        paddingRight: '20px',
      },
      minValue: {
        fontFamily: 'Nunito',
        fontSize: '15px',
        color: '#000000',
        marginBottom: '0px',
        float: 'left',
        width: '75px',
        display: 'flex',
      },
      maxValue: {
        fontFamily: 'Nunito',
        fontSize: '15px',
        color: '#000000',
        float: 'right',
        marginBottom: '0px',
        display: 'flex',
      },
      rail: {
        borderRadius: 4,
        height: 6,
        background: '#4150A4',
      },
      minInputLabel: {
        float: 'left',
        lineHeight: '34px',
        marginRight: '5px',
        fontFamily: 'Montserrat',
        fontSize: '11px',
        fontWeight: 500,
      },
      maxInputLabel: {
        float: 'left',
        lineHeight: '34px',
        marginRight: '5px',
        fontFamily: 'Montserrat',
        fontSize: '11px',
        fontWeight: 500,
      },
      thumb: {
        height: 16,
        width: 16,
        background: '#1F6BBF',
      },
      invalidThumb: {
        height: 16,
        width: 16,
        background: '#F44336',
      },
      track: {
        borderRadius: 4,
        height: 6,
        background: '#1F6BBF',
        '&~&': {
          background: '#142D64',
        },
      },
      invalidTrack: {
        borderRadius: 4,
        height: 6,
        background: '#F44336',
        '&~&': {
          background: '#142D64',
        },
      },
      upperBound: {
        fontFamily: 'Nunito',
        fontSize: '10px',
        color: '#000000',
        float: 'right',
        marginLeft: 'Auto',
        marginRight: 'Auto',
        marginBottom: '15px',
      },
      lowerBound: {
        fontFamily: 'Nunito',
        fontSize: '10px',
        color: '#000000',
        float: 'left',
        marginLeft: 'Auto',
        marginRight: 'Auto',
        marginBottom: '15px',
      },
      sliderText: {
        color: '#897E67',
        lineHeight: '120%',
        fontFamily: 'Nunito',
        fontSize: '14px',
        padding: '5px 15px 5px 0px',
        width: '100%',
        textAlign: 'right',
        background: '#D9C5A040',
        marginTop: '10px',
      },
      invalidSliderText: {
        color: '#D32F2F',
        lineHeight: '120%',
        fontFamily: 'Nunito',
        fontSize: '14px',
        padding: '5px 15px 5px 0px',
        width: '100%',
        textAlign: 'right',
        background: '#E57373',
        marginTop: '10px',
      },
      sliderListItem: {
        height: '15px',
      },
      listItemGutters: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '2px 5px 2px 8px',
      },
      lowerUpperBound: {
        height: '15px',
      },
    }
  },
  {
    section: SAMPLES,
    label: 'Sample Anatomic Site',
    apiPath: 'participantCountBySampleAnatomicSite',
    apiForFiltering: 'filterParticipantCountBySampleAnatomicSite',
    datafield: 'sample_anatomic_site',
    field: GROUP,
    type: InputTypes.CHECKBOX,
    sort_type: sortType.ALPHABET,
    show: true,
  },
  {
    section: SAMPLES,
    label: 'Sample tumor status',
    apiPath: 'participantCountByTumorStatus',
    apiForFiltering: 'filterParticipantCountByTumorStatus',
    datafield: 'sample_tumor_status',
    field: GROUP,
    type: InputTypes.CHECKBOX,
    sort_type: sortType.ALPHABET,
    show: true,
  },
  {
    section: SAMPLES,
    label: 'Tumor classification',
    apiPath: 'participantCountByTumorClassification',
    apiForFiltering: 'filterParticipantCountByTumorClassification',
    datafield: 'tumor_classification',
    field: GROUP,
    type: InputTypes.CHECKBOX,
    sort_type: sortType.ALPHABET,
    show: true,
  },
  {
    section: DATACATEGORY,
    label: 'Data Category',
    apiPath: 'ParticipantCountByDataCategory',
    apiForFiltering: 'filterParticipantCountByDataCategory',
    datafield: 'data_category',
    field: GROUP,
    type: InputTypes.CHECKBOX,
    sort_type: sortType.ALPHABET,
    show: true,
  },
  {
    section: DATACATEGORY,
    label: 'File Type',
    apiPath: 'participantCountByFileType',
    apiForFiltering: 'filterParticipantCountByFileType',
    datafield: 'file_type',
    field: GROUP,
    type: InputTypes.CHECKBOX,
    sort_type: sortType.ALPHABET,
    show: true,
  },
  {
    section: DATACATEGORY,
    label: 'File Mapping',
    apiPath: 'participantCountByFileMappingLevel',
    apiForFiltering: 'filterParticipantCountByFileMappingLevel',
    datafield: 'file_mapping_level',
    field: GROUP,
    type: InputTypes.CHECKBOX,
    sort_type: sortType.ALPHABET,
    show: true,
  },
  {
    section: STUDY,
    label: 'dbGaP accession',
    apiPath: 'participantCountByDBGAPAccession',
    apiForFiltering: 'filterParticipantCountByDBGAPAccession',
    datafield: 'dbgap_accession',
    field: GROUP,
    type: InputTypes.CHECKBOX,
    sort_type: sortType.ALPHABET,
    show: true,
  },
  {
    section: STUDY,
    label: 'Study Name',
    apiPath: 'participantCountByStudyTitle',
    apiForFiltering: 'filterParticipantCountByStudyTitle',
    datafield: 'study_name',
    field: GROUP,
    type: InputTypes.CHECKBOX,
    sort_type: sortType.ALPHABET,
    show: true,
  },
  {
    section: SEQUENCINGLIBRARY,
    label: 'Library selection',
    apiPath: 'participantCountByLibrarySelection',
    apiForFiltering: 'filterParticipantCountByLibrarySelection',
    datafield: 'library_selection',
    field: GROUP,
    type: InputTypes.CHECKBOX,
    sort_type: sortType.ALPHABET,
    show: true,
  },
  {
    section: SEQUENCINGLIBRARY,
    label: 'Library source material',
    apiPath: 'participantCountByLibrarySourceMaterial',
    apiForFiltering: 'filterParticipantCountByLibrarySourceMaterial',
    datafield: 'library_source_material',
    field: GROUP,
    type: InputTypes.CHECKBOX,
    sort_type: sortType.ALPHABET,
    show: true,
  },
  {
    section: SEQUENCINGLIBRARY,
    label: 'Library strategy',
    apiPath: 'participantCountByLibraryStrategy',
    apiForFiltering: 'filterParticipantCountByLibraryStrategy',
    datafield: 'library_strategy',
    field: GROUP,
    type: InputTypes.CHECKBOX,
    sort_type: sortType.ALPHABET,
    show: true,
  },
  {
    section: SEQUENCINGLIBRARY,
    label: 'Library source molecule',
    apiPath: 'participantCountByLibrarySourceMolecule',
    apiForFiltering: 'filterParticipantCountByLibrarySourceMolecule',
    datafield: 'library_source_molecule',
    field: GROUP,
    type: InputTypes.CHECKBOX,
    sort_type: sortType.ALPHABET,
    show: true,
  },
  // {
  //   section: CASES,
  //   label: 'Program',
  //   apiPath: 'subjectCountByProgram',
  //   apiForFiltering: 'filterSubjectCountByProgram',
  //   datafield: 'programs',
  //   field: GROUP,
  //   type: InputTypes.CHECKBOX,
  //   sort_type: sortType.ALPHABET,
  //   show: true,
  // },
  // {
  //   section: CASES,
  //   label: 'Arm',
  //   apiPath: 'subjectCountByStudy',
  //   apiForFiltering: 'filterSubjectCountByStudy',
  //   datafield: 'studies',
  //   field: GROUP,
  //   type: InputTypes.CHECKBOX,
  //   sort_type: sortType.ALPHABET,
  //   show: true,
  // },
  // {
  //   section: CASES,
  //   label: 'Diagnosis',
  //   apiPath: 'subjectCountByDiagnoses',
  //   apiForFiltering: 'filterSubjectCountByDiagnoses',
  //   datafield: 'diagnoses',
  //   field: GROUP,
  //   type: InputTypes.CHECKBOX,
  //   sort_type: sortType.ALPHABET,
  //   show: true,
  // },
  // {
  //   section: CASES,
  //   label: 'Recurrence Score',
  //   apiPath: 'subjectCountByRecurrenceScore',
  //   apiForFiltering: 'filterSubjectCountByRecurrenceScore',
  //   datafield: 'rc_scores',
  //   field: GROUP,
  //   type: InputTypes.CHECKBOX,
  //   sort_type: sortType.RANGE,
  //   show: true,
  // },
  // {
  //   section: CASES,
  //   label: 'Tumor Size',
  //   apiPath: 'subjectCountByTumorSize',
  //   apiForFiltering: 'filterSubjectCountByTumorSize',
  //   datafield: 'tumor_sizes',
  //   field: GROUP,
  //   type: InputTypes.CHECKBOX,
  //   sort_type: sortType.CUSTOM_NUMBER,
  //   show: true,
  // },
  // {
  //   section: CASES,
  //   label: 'Chemotherapy',
  //   apiPath: 'subjectCountByChemotherapyRegimen',
  //   apiForFiltering: 'filterSubjectCountByChemotherapyRegimen',
  //   datafield: 'chemo_regimen',
  //   field: GROUP,
  //   type: InputTypes.CHECKBOX,
  //   sort_type: sortType.ALPHABET,
  //   show: true,
  // },
  // {
  //   section: CASES,
  //   label: 'Tumor Grade',
  //   apiPath: 'subjectCountByTumorGrade',
  //   apiForFiltering: 'filterSubjectCountByTumorGrade',
  //   datafield: 'tumor_grades',
  //   field: GROUP,
  //   type: InputTypes.CHECKBOX,
  //   sort_type: sortType.ALPHABET,
  //   show: true,
  // },
  // {
  //   section: CASES,
  //   label: 'ER Status',
  //   apiPath: 'subjectCountByErStatus',
  //   apiForFiltering: 'filterSubjectCountByErStatus',
  //   datafield: 'er_status',
  //   field: GROUP,
  //   type: InputTypes.CHECKBOX,
  //   sort_type: sortType.ALPHABET,
  //   show: true,
  // },
  // {
  //   section: CASES,
  //   label: 'PR Status',
  //   apiPath: 'subjectCountByPrStatus',
  //   apiForFiltering: 'filterSubjectCountByPrStatus',
  //   datafield: 'pr_status',
  //   field: GROUP,
  //   type: InputTypes.CHECKBOX,
  //   sort_type: sortType.ALPHABET,
  //   show: true,
  // },
  // {
  //   section: CASES,
  //   label: 'Endocrine Therapy',
  //   apiPath: 'subjectCountByEndocrineTherapy',
  //   apiForFiltering: 'filterSubjectCountByEndocrineTherapy',
  //   datafield: 'endo_therapies',
  //   field: GROUP,
  //   type: InputTypes.CHECKBOX,
  //   sort_type: sortType.ALPHABET,
  //   show: true,
  // },
  // {
  //   section: CASES,
  //   label: 'Menopause Status',
  //   apiPath: 'subjectCountByMenopauseStatus',
  //   apiForFiltering: 'filterSubjectCountByMenopauseStatus',
  //   datafield: 'meno_status',
  //   field: GROUP,
  //   type: InputTypes.CHECKBOX,
  //   sort_type: sortType.ALPHABET,
  //   show: true,
  // },
  // {
  //   section: CASES,
  //   label: 'Age',
  //   apiPath: 'filterSubjectCountByAge',
  //   apiForFiltering: 'filterSubjectCountByAge',
  //   datafield: 'age_at_index',
  //   ApiLowerBoundName: 'lowerBound',
  //   ApiUpperBoundName: 'upperBound',
  //   show: true,
  //   slider: true,
  //   type: InputTypes.SLIDER,
  //   sort_type: 'none',
  //   minLowerBound: 0,
  //   maxUpperBound: 100,
  //   quantifier: 'Years',
  // },
  // {
  //   section: SAMPLES,
  //   label: 'Tissue Type',
  //   apiPath: 'subjectCountByTissueType',
  //   apiForFiltering: 'filterSubjectCountByTissueType',
  //   datafield: 'tissue_type',
  //   field: GROUP,
  //   type: InputTypes.CHECKBOX,
  //   sort_type: sortType.ALPHABET,
  //   show: true,
  // },
  // {
  //   section: SAMPLES,
  //   label: 'Tissue Composition',
  //   apiPath: 'subjectCountByTissueComposition',
  //   apiForFiltering: 'filterSubjectCountByTissueComposition',
  //   datafield: 'composition',
  //   field: GROUP,
  //   type: InputTypes.CHECKBOX,
  //   sort_type: sortType.ALPHABET,
  //   show: true,
  // },
  // {
  //   section: FILES,
  //   label: 'File Association',
  //   apiPath: 'subjectCountByFileAssociation',
  //   apiForFiltering: 'filterSubjectCountByFileAssociation',
  //   datafield: 'association',
  //   field: GROUP,
  //   type: InputTypes.CHECKBOX,
  //   sort_type: sortType.ALPHABET,
  //   show: true,
  // },
  // {
  //   section: FILES,
  //   label: 'File Type',
  //   apiPath: 'subjectCountByFileType',
  //   apiForFiltering: 'filterSubjectCountByFileType',
  //   datafield: 'file_type',
  //   field: GROUP,
  //   type: InputTypes.CHECKBOX,
  //   sort_type: sortType.ALPHABET,
  //   show: true,
  // },
];

// --------------- Dashboard Widgets configuration --------------
// Sunburst chart color scheme
export const SUNBURST_COLORS_LEVEL_1 = [
  '#7dc242',
  '#274fa5',
  '#79287c',
  '#f78f48',
  '#057ebd',
];

export const SUNBURST_COLORS_LEVEL_2 = [
  '#057ebd',
  '#f78f48',
  '#79287c',
  '#0e3151',
  '#057ebd',
  '#7dc242',
];

// A maximum of 6 widgets are allowed
// for donuts only the following are required: type, title, dataName
//
// type: 'sunburst' | 'donut'
// title: string
// dataName: string
// datatable_level1_field: string
// datatable_level1_colors: string[]
// datatable_level2_field: string
// datatable_level2_colors: string[]
// sliceTitle: string (optional)
export const widgetConfig = [
  // {
  //   type: 'sunburst',
  //   title: 'Programs and Arms',
  //   dataName: 'armsByPrograms',
  //   datatable_level1_field: 'program',
  //   datatable_level1_colors: SUNBURST_COLORS_LEVEL_1,
  //   datatable_level2_field: 'arm',
  //   datatable_level2_colors: SUNBURST_COLORS_LEVEL_2,
  // },
  {
    type: 'donut',
    title: 'Study',
    dataName: 'participantCountByStudy',
    sliceTitle: 'Participants',
  },
  {
    type: 'donut',
    title: 'Diagnosis',
    dataName: 'participantCountByDiagnosis',
    sliceTitle: 'Participants',
  },
  {
    type: 'bar',
    title: 'Age at Diagnosis (years)',
    dataName: 'participantCountByDiagnosisAge',
    width: '100%',
    height: 210,
  },
  {
    type: 'donut',
    title: 'Sex',
    dataName: 'participantCountBySexAtBirth',
    sliceTitle: 'Participants',
  },
  {
    type: 'donut',
    title: 'Race',
    dataName: 'participantCountByRace',
    sliceTitle: 'Participants',
  },
  {
    type: 'donut',
    title: 'Data Category',
    dataName: 'participantCountByDataCategory',
    sliceTitle: 'Participants',
  },
];


// --------------- query url configuration --------------
// Facets, tab, pagination paramters
export const queryParams = [
  'p_id', 'u', 'u_fc', 'u_um', 'sex_at_birth', 'race',
  'age_at_diagnosis', 'diagnosis', 'diagnosis_anatomic_site', 'diagnosis_classification_system', 'diagnosis_basis', 'disease_phase',
  'treatment_type', 'treatment_agent', 'age_at_treatment_start', 'response_category', 'age_at_response', 
  'age_at_last_known_survival_status', 'first_event', 'last_known_survival_status', 
  'participant_age_at_collection', 'sample_anatomic_site', 'sample_tumor_status', 'tumor_classification', 
  'data_category', 'file_type', 'file_mapping_level', 'dbgap_accession', 'study_name', 
  'library_selection', 'library_strategy', 'library_source_material', 'library_source_molecule',
  'tab'
];
